import { makeStyles } from '@material-ui/core/styles'

//#region Style Section
export const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  mainAreaStyle: {
    paddingLeft: theme.spacing(4),
    // paddingLeft: '3.75rem',
  },
  filterBarStyle: {
    paddingRight: theme.spacing(3),
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerClose: {
    overflowX: 'hidden',
    width: theme.spacing(6),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: '#EAECEC',
  },
  listStyle: {
    postion: 'absolute',
  },
  listItemStyle: {
    margin: '4vh 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(7),
    // paddingTop: theme.spacing(2),
    // paddingLeft: theme.spacing(8),
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      background: theme.palette.primary.main,
    },
  },

  defaultMenuIconStyle: {
    fill: '#848484',
    height: '25',
    width: '25',
  },
  onSelectMenuIconStyle: {
    fill: 'white',
    fontWeight: 600,
    height: '25',
    width: '25',
  },
  hideIcons: {
    display: 'none',
  },
  menuIconRoot: {
    color: 'blue',
    minWidth: 20,
  },
}))
//#endregion
