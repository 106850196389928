/**
 * This module re-exports all pages of the app as lazy loaded components
 * @module asyncPages
 * @summary File containing all pages as lazy loaded components
 */

import React from 'react'
import Loadable from 'react-loadable'
import Loader from 'packages/common/ui/Loader'

/**
 * @summary Load Home Page
 */
export const AsyncHomeScreenDashboard = Loadable({
  loader: () => import('packages/core/module/HomeScreen'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Load Summary Page
 */
export const AsyncSummaryScreenDashboard = Loadable({
  loader: () => import('packages/core/module/SummaryScreen'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Load Table of contents Page
 */
export const AsyncTableOfContentPageDashboard = Loadable({
  loader: () => import('packages/core/module/TableOfContentsPage'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Service Consumption Indicators Page
 */
export const AsyncRidershipPageDashboard = Loadable({
  loader: () => import('packages/core/module/RidershipPage'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Financial Indicators Page
 */
export const AsyncFinancialIndicatorsDashboard = Loadable({
  loader: () => import('packages/core/module/FinancialIndicators'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Fleet Performance Page
 */
export const AsyncFleetPerformanceDashboard = Loadable({
  loader: () => import('packages/core/module/FleetPerformance'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Service Quality Page
 */
export const AsyncServiceQualityDashboard = Loadable({
  loader: () => import('packages/core/module/ServiceQuality'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Skipped Stops Page
 */
export const AsyncSkippedStopsDashboard = Loadable({
  loader: () => import('packages/core/module/SkippedStops'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Skipped Stops Page
 */
export const AsyncRoutePerformanceDashboard = Loadable({
  loader: () => import('packages/core/module/RoutePerformance'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Financial Analysis Page
 */
export const AsyncFinancialAnalysisDashboard = Loadable({
  loader: () => import('packages/core/module/FinancialAnalysis'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Stops Analysis Page
 */
export const AsyncStopsAnalysisDashboard = Loadable({
  loader: () => import('packages/core/module/StopsAnalysis'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Ridership Analysis Page
 */
export const AsyncRidershipAnalysisDashboard = Loadable({
  loader: () => import('packages/core/module/RidershipAnalysis'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Strategic Planning Page
 */
// export const AsyncStrategicPlanningDashboard = Loadable({
//   loader: () => import('packages/core/module/StrategicPlanning'),
//   loading: () => <Loader fullscreen={true} />,
// })

/**
 * @summary Operational Planning Page
 */
// export const AsyncOperationalPlanningDashboard = Loadable({
//   loader: () => import('packages/core/module/OperationalPlanning'),
//   loading: () => <Loader fullscreen={true} />,
// })

/**
 * @summary Questiona and Answer Page
 */
// export const AsyncQuestionAndAnswer = Loadable({
//   loader: () => import('packages/core/module/QuestionAndAnswer'),
//   loading: () => <Loader fullscreen={true} />,
// })
