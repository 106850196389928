/**
 * Stores mapping between a page's key and it's name, icon, component and path
 * @module pagesConfig
 * @summary Page configurations
 */

import * as pages from './asyncPages.js'
import { ReactComponent as Home } from 'packages/common/resource/svg/home.svg'
import { ReactComponent as FinancialIndicators } from 'packages/common/resource/svg/Financial Indicators.svg'
import { ReactComponent as Ridership } from 'packages/common/resource/svg/Ridership.svg'
import { ReactComponent as FleetPerformance } from 'packages/common/resource/svg/Fleet Performance.svg'
import { ReactComponent as ServiceQuality } from 'packages/common/resource/svg/Service Quality.svg'
import { ReactComponent as Summary } from 'packages/common/resource/svg/Summary.svg'
import { ReactComponent as RoutePerformance } from 'packages/common/resource/svg/Route wise Performance.svg'
import { ReactComponent as StopsAnalysis } from 'packages/common/resource/svg/Stops Analysis.svg'
import { ReactComponent as FinancialAnalysis } from 'packages/common/resource/svg/Financial Analysis.svg'
import { ReactComponent as RidershipAnalysis } from 'packages/common/resource/svg/Ridership Analysis.svg'
import { ReactComponent as SkippedStops } from 'packages/common/resource/svg/Skipped Stops.svg'
import { ReactComponent as TableOfContent } from 'packages/common/resource/svg/TableOfContents.svg'

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'

export default [
  {
    name: 'Home',
    icon: Home,
    path: '/home/dashboard',
    order: 1,
    component: pages.AsyncHomeScreenDashboard,
    isDisabled: false,
  },
  {
    name: 'Summary',
    icon: Summary,
    path: '/home/Summary',
    order: 2,
    component: pages.AsyncSummaryScreenDashboard,
    isDisabled: false,
  },
  {
    name: 'Table of Contents',
    icon: TableOfContent,
    path: '/home/TableofContents',
    order: 3,
    component: pages.AsyncTableOfContentPageDashboard,
    isDisabled: false,
  },
  {
    name: 'Ridership',
    icon: Ridership,
    path: '/home/RidershipPage/',
    order: 3,
    component: pages.AsyncRidershipPageDashboard,
    isDisabled: false,
  },
  {
    name: 'Financial Indicators',
    icon: FinancialIndicators,
    path: '/home/FinancialIndicators/',
    order: 4,
    component: pages.AsyncFinancialIndicatorsDashboard,
    isDisabled: false,
  },
  {
    name: 'Fleet Performance',
    icon: FleetPerformance,
    path: '/home/FleetPerformance/',
    order: 5,
    component: pages.AsyncFleetPerformanceDashboard,
    isDisabled: false,
  },
  {
    name: 'Service Quality',
    icon: ServiceQuality,
    path: '/home/ServiceQuality/',
    order: 6,
    component: pages.AsyncServiceQualityDashboard,
    isDisabled: false,
  },
  {
    name: 'Skipped Stops',
    icon: SkippedStops,
    path: '/home/SkippedStops/',
    order: 7,
    component: pages.AsyncSkippedStopsDashboard,
    isDisabled: false,
  },
  {
    name: 'Route Wise Performance',
    icon: RoutePerformance,
    path: '/home/RoutePerformance/',
    order: 8,
    component: pages.AsyncRoutePerformanceDashboard,
    isDisabled: false,
  },
  {
    name: 'Financial Analysis',
    icon: FinancialAnalysis,
    path: '/home/FinancialAnalysis/',
    order: 9,
    component: pages.AsyncFinancialAnalysisDashboard,
    isDisabled: false,
  },
  {
    name: 'Stops Analysis',
    icon: StopsAnalysis,
    path: '/home/StopsAnalysis/',
    order: 10,
    component: pages.AsyncStopsAnalysisDashboard,
    isDisabled: false,
  },
  {
    name: 'Ridership Analysis',
    icon: RidershipAnalysis,
    path: '/home/RidershipAnalysis/',
    order: 11,
    component: pages.AsyncRidershipAnalysisDashboard,
    isDisabled: false,
  },
]
