//#region Import Section
import React from 'react'
import clsx from 'clsx'
import DrawerList from 'config/drawerConfig'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import { NavLink, withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from 'packages/common/router'
import TripDetails from '../../Trips/TripDetails/TripDetails'
import FilterSelection from '../FilterSelection/FilterSelection'
import { useStyles } from './SideMenubarCSS'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
//#endregion

// Each Component will call getFleet Analytics.
// to those each component will send parameters from SideMenubar using context.

//#region Sidebar Component
const SideMenuBar = (props) => {
  const classes = useStyles()
  const {
    history: {
      location: { pathname },
    },
  } = props
  console.log('path name', pathname)
  return (
    <div className={classes.root}>
      {/* <Grid item md={1} xl="auto"> */}
      <Drawer
        variant="permanent"
        // className={classes.drawerClose}
        classes={{
          paper: classes.drawerClose,
        }}
      >
        <List className={classes.listStyle}>
          {DrawerList.map((drawerItem) => (
            <Tooltip
              title={drawerItem.name}
              placement="right"
              TransitionComponent={Zoom}
            >
              <ListItem
                key={drawerItem.order}
                button
                disabled={drawerItem.isDisabled}
                className={(() => {
                  console.log('pathname', drawerItem.path, pathname)
                  if (drawerItem.path === '/home/RidershipAnalysis/') {
                    console.log('hide icon')
                    return classes.hideIcons
                  } else if (drawerItem.path === '/home/FinancialAnalysis/') {
                    return classes.hideIcons
                  } else if (drawerItem.path === '/home/SkippedStops/') {
                    return classes.hideIcons
                  }
                  return classes.listItemStyle
                })()}
                component={React.forwardRef((props, ref) => (
                  <NavLink
                    innerRef={ref}
                    to={drawerItem.path}
                    activeClassName={classes.menuItem}
                    {...props}
                  />
                ))}
              >
                <ListItemIcon
                  classes={{
                    root: classes.menuIconRoot,
                  }}
                >
                  <drawerItem.icon
                    // fill="white"
                    className={
                      drawerItem.path === pathname
                        ? classes.onSelectMenuIconStyle
                        : classes.defaultMenuIconStyle
                    }
                  />
                </ListItemIcon>
                {/* <ListItemText>
                <Typography style={{ fontSize: "0.85rem" }}>
                  {drawerItem.name}
                </Typography>
              </ListItemText> */}
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Drawer>
      {/* </Grid> */}
      <Grid container spacing={1} style={{ background: 'white' }}>
        <Grid item md={12} xl={12}>
          <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <Switch>
              {DrawerList.map(({ path, component }, index) => {
                return (
                  <PrivateRoute
                    exact
                    key={index}
                    path={path}
                    component={component}
                  />
                )
              })}
              <PrivateRoute
                exact
                path="/home/trips/:tripsIndex"
                component={TripDetails}
              />
              {/* <PrivateRoute exact path="/home/planner" component={Planner} />
          <PrivateRoute exact path="/home/trips" component={Trips} /> */}
              <Redirect
                to={{
                  pathname: DrawerList[0].path,
                }}
              />
            </Switch>
          </main>
        </Grid>
        {/* <Grid item md={2} xl={2}>
          <FilterSelection />
        </Grid> */}
      </Grid>
    </div>
  )
}
//#endregion

export default withRouter(SideMenuBar)
